import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import { Groups, ModalType, PARTNER_VOICE, STATUS_CALLEE, VENDOR, initialFilter } from 'utils/constant';
import Pagination from 'components/Pagination/Pagination';
import { API_ROUTES } from 'utils/constant';
import Row from './components/Row';
import InputSearch from 'components/InputSearch/InputSearch';
import DatePicker from 'components/DatePicker/DatePicker';
import { downloadFile, formatDate } from 'utils/helpers';
import moment from 'moment';
import { get } from 'lodash';
import AuthorizationGroupWrapper from 'components/AuthorizationGroupWrapper/AuthorizationGroupWrapper';
import Loading from 'components/Layout/Loading';
import AddOAModal from './components/AddOAModal';

const ZaloOA = () => {
  const [filter, setFilter] = useState({ ...initialFilter });
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [callstatus, setCallStatus] = useState('');
  const [vendor, setVendor] = useState('');
  const [partner, setPartner] = useState('');
  const toast = useToast();
  const [{ data, loading }, refetch] = useAxios(
    {
      url: API_ROUTES.ZaloOA,
      params: filter,
    },
    {
      useCache: false,
    }
  );

  const { isOpen: isOpenAddModal, onOpen: onOpenAddModal, onClose: onCloseAddModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.Add]: onOpenAddModal,
    }),
    [onOpenAddModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseAddModal,
    }),
    [onCloseAddModal]
  );
  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
  };

  const onClearSearch = () => {
    setFilter(initialFilter);
    setSearchKeyword('');
  };

  const onChangeSearch = event => {
    event.persist();
    setSearchKeyword(event.target.value);
  };

  const onFilter = () => {
    setFilter({
      ...filter,
      pageIndex: 0,
      searchKeyword,
      startDate: startDate ? moment(startDate).toISOString() : '',
      endDate: endDate ? moment(endDate).endOf('days').toISOString() : '',
    });
  };

  const onChangeDate = type => date => {
    if (type === 'startDate') {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const onResetFilter = () => {
    setFilter(initialFilter);
    setStartDate('');
    setEndDate('');
    setCallStatus('');
    setSearchKeyword('');
  };
  return (
    <>
      <Box bg="white" mt={{ base: '120px', md: '75px' }} p="10">
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Flex align="center" justifyContent="space-between">
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Danh sách zalo doanh nghiệp
              </Text>
              <Button variant="primary" maxH="30px" my="10px" onClick={onOpenAddModal}>
                Add OA
              </Button>
            </Flex>
          </CardHeader>

          <CardBody>
            <Stack>
              <Flex>
                <InputSearch value={searchKeyword} onChange={onChangeSearch} onClearSearch={onClearSearch} />
              </Flex>
              <Flex>
                <FormControl display="flex" alignItems="center" maxW="300px" mr="12px">
                  <FormLabel m="0" pr="10px">
                    Từ ngày:
                  </FormLabel>
                  <DatePicker selectedDate={startDate} onChange={date => onChangeDate('startDate')(date)} />
                </FormControl>
                <FormControl display="flex" alignItems="center" maxW="300px" mr="12px">
                  <FormLabel m="0" pr="10px">
                    Đến ngày:
                  </FormLabel>
                  <DatePicker selectedDate={endDate} minDate={startDate} onChange={date => onChangeDate('endDate')(date)} />
                </FormControl>
                <Button variant="primary" maxH="30px" m="10px" onClick={onFilter}>
                  Lọc
                </Button>
                <Button variant="primary" maxH="30px" m="10px" onClick={onResetFilter}>
                  Cài lại
                </Button>
              </Flex>
            </Stack>
            {loading ? (
              <Loading />
            ) : (
              <>
                <Stack overflow="auto">
                  <Table variant="simple" color={textColor}>
                    <Thead>
                      <Tr my=".8rem" pl="0px" color="gray.400">
                        <Th pl="0px" borderColor={borderColor} color="gray.400">
                          OA ID
                        </Th>
                        <Th borderColor={borderColor} color="gray.400">
                          OA Name
                        </Th>
                        <Th pl="0px" borderColor={borderColor} color="gray.400">
                          Status
                        </Th>
                        <Th pl="0px" borderColor={borderColor} color="gray.400">
                          action
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data?.data?.map((row, index, arr) => (
                        <Row key={row.id} detail={row} isLast={index === arr.length - 1 ? true : false} />
                      ))}
                      {isEmpty(data?.data) && !loading && (
                        <Tr>
                          <Td colSpan="9">
                            <Box textAlign="center" py="80px">
                              No data
                            </Box>
                          </Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </Stack>

                <Flex justifyContent={'flex-end'}>
                  {!isEmpty(data?.data) && (
                    <Pagination
                      page={data?.pagination?.page}
                      pageLength={data?.pagination?.pageSize}
                      totalRecords={data?.pagination?.count}
                      onPageChange={(page, pageLength) => {
                        setFilter({
                          ...filter,
                          pageSize: pageLength,
                          pageIndex: page - 1,
                        });
                      }}
                    />
                  )}
                </Flex>
              </>
            )}
          </CardBody>
        </Card>
      </Box>
      {isOpenAddModal && <AddOAModal isOpen={isOpenAddModal} onClose={handelCloseModal} refetchData={refetch} />}
    </>
  );
};

export default ZaloOA;
