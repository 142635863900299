import React, { useEffect } from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import InputController from 'components/Form/InputController';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { useForm } from 'react-hook-form';

export default function DetailOA({ data }) {
  const params = useParams();
  const { id } = params || {};
  const history = useHistory();
  const { control, reset } = useForm();
  useEffect(() => {
    reset({
      ...data,
    });
  }, [data]);
  if(!data) return null
  return (
    <Card mt="14px">
      <CardHeader p="6px 0px 22px 0px">
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontSize="xl" fontWeight="bold">
            Chi tiết
          </Text>
        </Flex>
      </CardHeader>
      <CardBody pb={4}>
        {Object.entries(data)
          ?.filter(([key]) =>
            [
              'oa_id',
              'oa_name',
              'redirectUri',
              'codeChallenge',
              'refreshToken',
              'state',
              'authorizationCode',
              'expiresIn',
              'status',
            ].includes(key)
          )
          .map(([key]) => (
            <InputController key={key} control={control} name={key} label={key} styleContainer={{ pt: '4' }} readOnly />
          ))}

        <Flex pt={6} alignItems="flex-end" justifyContent="flex-end">
          <Button
            onClick={() => {
              history.goBack();
            }}
          >
            Quay lại
          </Button>
        </Flex>
      </CardBody>
    </Card>
  );
}
