export const StorageKey = {
  UrlZaloAuth: 'urlZaloAuth',
  UrlZaloAuthDetail:'UrlZaloAuthDetail'
};

export const Roles = {
  ADMIN: 'admin',
  GUEST: 'guest',
  USER: 'user',
};
export const Groups = {
  GROUP_ADMIN: 'GROUP_ADMIN',
  NOBODY: 'NOBODY',
};

export const CountryCodeDefault = {
  GB: 'GB',
};

export const initialFilter = {
  pageSize: 10,
  pageIndex: 0,
};

export const STATUS_CODE = {
  Success: 0,
};

export const STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECT: 'reject',
};

export const EMAIL_REGEX = /^([a-zA-Z0-9])+(([a-zA-Z0-9_.-])*)+([a-zA-Z0-9])@(([a-zA-Z0-9-])+([a-zA-Z0-9])+\.)([a-zA-Z]{2,})((\.+([a-zA-Z]{2,}))*)$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/;
export const PHONE_REGEX = /^(0|84|\+84|084)\d{9}$/;
export const LINK_REGEX = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
export const IP_REGEX = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/g;

export const defautlPassword = '2Qlive2023@';
export const MAX_VIDEO_UPLOAD = 1024 * 1024 * 30;

export const ROOT_API = process.env.REACT_APP_API_HOST;
export const TINY_API_KEY = process.env.REACT_APP_TINY_API_KEY;

export const API_ROUTES = {
  Users: `${ROOT_API}/api/v1/user/users`,
  Profile: `${ROOT_API}/api/v1/user/profile`,
  LoginPath: `${ROOT_API}/api/v1/user/login`,
  RegisterUser: `${ROOT_API}/api/v1/user/register`,
  UpdateUser: `${ROOT_API}/api/v1/user/update`,
  ChangePassUser: `${ROOT_API}/api/v1/user/change-pw`,
  ResetPassUser: `${ROOT_API}/api/v1/user/reset-password`,
  SendMail: `${ROOT_API}/api/v1/send-mail`,
  SMSDaily: `${ROOT_API}/api/v1/zalo/sms-daily`,
  ZaloAuth: `${ROOT_API}/api/v1/zalo-auth`,
  ZaloSetting: `${ROOT_API}/api/v1/zalo/settings`,
  AllTemplate: `${ROOT_API}/api/v1/zalo/template-all`,
  ZnsQuota: `${ROOT_API}/api/v1/zalo/quota`,
  ZnsQuality: `${ROOT_API}/api/v1/zalo/quality`,
  VoiceOtp: `${ROOT_API}/api/v1/voice-otp/histories`,
  ZaloOA: `${ROOT_API}/api/v1/zalo/all`,
  SMS: `${ROOT_API}/api/v1/zalo/histories`,
  OverviewSMS: `${ROOT_API}/api/v1/sms/overview`,
  IPS: `${ROOT_API}/api/v1/ips`,
  OA: `${ROOT_API}/api/v1/zalo/create`,
  DeleteIPS: `${ROOT_API}/api/v1/ips/delete`,
  ExportHistory: `${ROOT_API}/api/v1/export-call-out`,
  ChangeStatusTemplate: `${ROOT_API}/api/v1/zalo/change-status-template`,
  ZaloOADetail: `${ROOT_API}/api/v1/zalo/detail`
};

export const BrandMailKey = {
  Vipphim: 'VIPPHIM',
  Eday: '8DAY',
  Ebbb: '888B',
  Tqnews: '2QNEWS',
  Tqsport: '2QSPORT',
  Tqlive: '2QLIVE',
};

export const ModalType = {
  Add: 'add',
  Delete: 'delete',
  Import: 'import',
  Preview: 'preview',
  Detail: 'detail',
};
export const STATUS_CALLEE = {
  ANSWERED: 'ANSWERED',
  BUSY: 'BUSY',
  NO_ANSWER: 'NO ANSWER',
  FAIL: 'FAIL',
};
export const STATUS_ZNS = {
  0: 'THÀNH CÔNG',
  '-1': 'THẤT BẠI',
};
export const VENDOR = {
  DTEL: 'DTEL',
  TPS: 'TPS',
  ZALO: 'ZALO',
};
export const PARTNER_VOICE = {
  AIG: 'AIG',
  OTHER: 'OTHER',
};