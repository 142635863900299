import React from 'react';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import AuthZaloUrl from './Components/AuthZaloUrl';
import Template from './Components/Template';

const Setting = () => {
  const textColor = useColorModeValue('gray.700', 'white');

  return (
    <>
      <Box bg="white" mt={{ base: '120px', md: '75px' }} p="10">
        <AuthZaloUrl />
        <Template />
      </Box>
    </>
  );
};

export default Setting;
