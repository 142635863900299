import React from 'react';
import { Flex, IconButton, Td, Text, Tr, useColorModeValue, useToast } from '@chakra-ui/react';
import { BiDetail } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
function Row({ detail, isLast }) {
  const textColor = useColorModeValue('gray.500', 'white');
  const titleColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
const history=useHistory()
  return (
    <Tr>
      <Td pl="0px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={titleColor} fontWeight="bold" noOfLines={2} >
            {detail?.oa_id}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" noOfLines={2}>
            {detail?.oa_name}
          </Text>
        </Flex>
      </Td>
      <Td pl="0px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold"  noOfLines={2}>
            {detail?.status === 0 ? "ACTIVE" : "INACTIVE"}
          </Text>
        </Flex>
      </Td> <Td pl="0px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold"  noOfLines={2}>
          <IconButton
              p={2}
              bg="transparent"
              onClick={()=>history.push(`zalo-official-account/${detail?.oa_id}`)}
              color={undefined}
            >
              <BiDetail />
            </IconButton>
          </Text>
        </Flex>
      </Td>
    </Tr>
  );
}

export default Row;
